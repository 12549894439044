.gradient-border {
    background: linear-gradient(135deg, #1e3a8a, #701a75 71%);
    padding: 3px; /* Adjust the width of the border */
    border-radius: 10%; 
  }

  /* Gradient Text for Brand Name with fallback color */
.brand-name {
  background: linear-gradient(to right, #fcfcfc, #f5c2a0, #f16ff8);
  -webkit-background-clip: text; /* For WebKit browsers */
  background-clip: text; /* Standard property */
  -webkit-text-fill-color: transparent; /* For WebKit browsers */
  color: #FBBF24; /* Fallback color */
  animation: pulse 2s infinite;
}

/* Animation for Brand Name */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
